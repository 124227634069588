
$px-values: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100);



//scale ratio
$scale-base-size: 10px;

$scale-ratio-xxs: 0.5;
$scale-ratio-xs: 0.5;
$scale-ratio-sm: 0.6;
$scale-ratio-md: 0.75;
$scale-ratio-lg:  0.8;
$scale-ratio-xl: 0.8;


// bootstrap
$main-left: 15px;
$main-right: 15px;
$main-left-md: 4.5rem;
$main-right-md: 4.5rem;

// container
$container-max-width: 157rem;
$container-padding-left: $main-left;
$container-padding-right: $main-right;
$container-padding-left-md: $main-left-md;
$container-padding-right-md: $main-right-md;

// fluid container
$fluid-container-padding-left: $main-left;
$fluid-container-padding-right: $main-right;
$fluid-container-padding-left-md: $main-left-md;
$fluid-container-padding-right-md: $main-right-md;
$fluid-container-padding-left-xl: 12rem;
$fluid-container-padding-right-xl: 12rem;


// row
$row-margin-left: (-$main-left);
$row-margin-right: (-$main-right);
$row-margin-left-md: (-$main-left-md);
$row-margin-right-md: (-$main-right-md);

// col
$col-padding-left: $main-left;
$col-padding-right: $main-right;
$col-padding-left-md: $main-left-md;
$col-padding-right-md: $main-right-md;

// typography
$font-family: "aktiv-grotesk", sans-serif;
$font-size: 16px;

// perex
$font-size-perex: 22px;
$min-font-size-perex: 18px;
$line-height-perex: 26px;

// small text
$font-size-small: 14px;
$min-font-size-small: 14px;
$line-height-small: 18px;

// main colors
$color-link: #06D978;
$main-color: #1a7a51;
$second-color: #27312d;
$color-line: #C0CCC6;
$base-black: #000;
$base-white: #fff;
$color-gray: #636a67;
$color-light-gray: #f2f5f4;
// button primary
$btn-primary-background: $main-color;
$btn-primary-color: $base-white;

// button default
$btn-default-background: $second-color;
$btn-default-color: $base-black;

// ul, li
$ul-bullet: $main-color;
$ol-number: $main-color;

// table
$table-even-background: #f9faf9 ;
$table-tr-border: #c0ccc6;

// form
$color-placeholder: #000;

// inputs
$border-color: #c0ccc6;
$input-background: #f2f5f4;
$focus-border-color: $main-color;
$input-height: 50px;
$textarea-height: $input-height + 30px;

// upload
$upload-background: $main-color;
$upload-color: $base-white;
$file-name-background: $second-color;
$file-name-color: $base-black;

// form radio
$radio-background: #eeeff4;
$radio-border: #eeeff4;
$radio-active-border: $color-link;
$radio-after-background: $color-link; 

// form checkbox
$checkbox-background: #eeeff4;
$checkbox-border: #eeeff4;
$checkbox-active-border: $main-color;
$checkbox-after-background: $color-link;

// form error
$error-input-border: #d40511;
$error-label-color: #d40511;
$error-blink-start: #fff;
$error-blink-final: #fec7ca;
$use-blink-error: 1;

// scale

$scale-xxl: 1;
$scale-xl: 0.8;
$scale-lg: 0.7;
$scale-md: 0.6;
$scale-sm: 0.5;
$scale-xs: 0.4;
$scale-xxs: 0.4;

$min-font-size: 14px;
$min-padding: 10px;
$min-margin: 10px;


//header height:
$header-h-small: 50px;
$header-h-medium: 60px;
$header-h-large: 8rem;
