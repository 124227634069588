
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


.box-preview {
		@include flex-block;
		@include flex-direction(column);
		margin: auto -15px; 
		@include media(sm) {
				@include flex-flow(row, wrap);
		}
		@include media(lg) {
				margin-left: -5px;
		}
		@include media(xxl) {
				margin: auto #{-$main-left-md};
		}
		&-item {
			 	background-size: cover;
				background-position: center center;
				color: $base-white;
				border-bottom: 2px solid $base-white;
				width: 100%;
				text-align: center;
				@include media(md) {
						@include flex(0 1 50%);
						max-width: 50%;
						border-left: 2px solid $base-white;
						text-align: left;
				}
				@include media(lg) {
						border-bottom: 0.2rem solid $base-white;
						border-left: 0.2rem solid $base-white;

				}
				&:nth-child(2n + 1) {
						@include media(lg) {
							  border-left: 0;
						}
				}
				&-inner {
						background-color: rgba($main-color, .8);
						padding: 8rem 7.5rem 8rem;
						height: 100%;
				}
				.perex {
						font-size: 17px;
						margin-top: 15px;
						color: $base-white;
						@include media(lg) {
								font-size: 1.9rem;
								margin-top: 2.2rem;
						}
						@include media(xxl) {
								font-size: 1.7rem;
								margin-top: 2rem;
						}
				}
				a {
						margin-top: 4rem;
						@include media(lg) {
								margin-top: 3rem;
						}
				}
		}
		&.items-3 {
				.box-preview-item {
						@include media(xl) {
								border-left: 0.2rem solid $base-white;
						}
						@include media(xl) {
								@include flex(0 1 33.3333333%);
								max-width: 33.3333333%;
						}
						&:nth-child(3n + 1) {
								@include media(xl) {
										border-left: 0 !important;
								}
						}
				}
		}
}

.product-text-block {
		overflow: hidden;
		.perex {
			margin-bottom: 3rem;
		}
		.text-block-item {
				margin-bottom: 6rem;
		}
}
