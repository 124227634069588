
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
    font-family: 'Core-icons' !important;
}

*:before,
*:after{
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

form,
html,
body{
    font-family:$font-family;
    height:100%;
    color: $second-color;
}

html {
		font-size: $scale-base-size*$scale-ratio-xxs;
		@include media(xs) {
				font-size: $scale-base-size*$scale-ratio-xs;
		}
		@include media(sm) {
				font-size: $scale-base-size*$scale-ratio-sm;
		}
		@include media(md) {
				font-size: $scale-base-size*$scale-ratio-md;
		}
		@include media(lg) {
				font-size: $scale-base-size*$scale-ratio-lg;
		}
		@include media(xl) {
				font-size: $scale-base-size*$scale-ratio-xl;
		}
		@include media(xxl) {
				font-size: $scale-base-size;
		}
}

body {
		max-width: 2000px;
		margin: 0 auto;
		position: relative;
		@include pux-static-font(16px);
		color: $second-color;
}

@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
       only screen and(      -o-min-device-pixel-ratio: 5/4 ),
       only screen and( min-resolution: 120dpi ),
       only screen and( min-resolution: 1.25dppx ) {
		html {
				font-size: $scale-base-size*$scale-ratio-xxs;
				@include media(xs) {
						font-size: $scale-base-size*$scale-ratio-xs;
				}
				@include media(sm) {
						font-size: $scale-base-size*$scale-ratio-sm;
				}
				@include media(md) {
						font-size: $scale-base-size*$scale-ratio-md;
				}
				@include media(lg) {
						font-size: $scale-base-size*$scale-ratio-lg*0.8;
				}
				@include media(xl) {
						font-size: $scale-base-size*$scale-ratio-xl*0.8;
				}
				@include media(xxl) {
						font-size: $scale-base-size*0.8;
				}
		}
		body {
				 @include pux-static-font(16px);
		}

}


img{
    max-width:100%;
    height:auto !important;
}

@each $value in $px-values {
	@each $value in $px-values {
	  .pt-#{strip-unit($value)} {
				@include pux-scale-with-min("padding-top", $value, 20px);
	  }
	}


	@each $value in $px-values {
	  .pb-#{strip-unit($value)} {
				@include pux-scale-with-min("padding-bottom", $value, 20px);
	  }
	}



	@each $value in $px-values {
	  .mt-#{strip-unit($value)} {
				@include pux-scale-with-min("margin-top", $value, 20px);
	  }
	}



	@each $value in $px-values {
	  .mb-#{strip-unit($value)} {
				@include pux-scale-with-min("margin-bottom", $value, 20px);
	  }
	}
}

h1,
.h1{
		font-size: 6rem;
		line-height: 7rem;
		font-weight: 800;
    //@include pux-scale-font(60px, 30px);
}

h2,
.h2{
		font-weight: 800;
		font-size: rem(xxs, 24px);
		line-height: 1.5;
		@include media(md) {
			font-size: 4rem;
			line-height: 5rem;
		}
}

h3,
.h3{
		font-weight: 800;
		font-size: rem(xxs, 22px);
		line-height: 1.5;
		@include media(md) {
			font-size: 3rem;
			line-height: 4rem;
		}
    //@include pux-scale-font(30px, 18px);
}

h4,
.h4{
		font-weight: 800;
		font-size: rem(xxs, 20px);
		line-height: 1.5;
		@include media(md) {
			font-size: 2.2rem;
			line-height: 3rem;
		}
    //@include pux-scale-font(18px, 16px);
}

big,
.perex{
		font-size: rem(xxs, 18px);
		line-height: 1.5;
		font-weight: 300;
		color: $color-gray;
		@include media(md) {
				font-size: 2.2rem;
				line-height: 1.7;
		}
    //@include pux-scale-font($font-size-perex, $min-font-size-perex, $line-height-perex);
}

small,
.text-small{
		font-size: 1.4rem;
		line-height: 1.8rem;
		font-weight: 300;
    //@include pux-scale-font($font-size-small, $min-font-size-small, $line-height-small);
}

a{
    color: $color-link;
    cursor: pointer;
		@include transition;
    &:focus,
    &:hover{
        outline:none;
				text-decoration: none;
				border: 0;
				color: $main-color;
    }
}

.btn {
		padding: 0;
		margin: 0;
		border: 0;
		position: relative;
	  svg {
		    display: block;
				@include pux-static-size("width", 110px);
				@include pux-static-size("height", 50px);
				@include transition-property("height, width");
	  }
	  &-border {
	      @include pux-static-size("stroke-dasharray", 53px, 4px, 156px, 4px, 5000px, 4px);
				@include pux-static-size("stroke-width", 4px);
	      fill: transparent;
	      stroke: $color-link;
				@include transition(0);
	      transition: stroke-width .5s, stroke-dashoffset .5s, stroke-dasharray .5s;
	  }
	  &-text {
	      position: absolute;
	      display: flex;
	      align-items: center;
	      justify-content: center;
	      top: 0;
	      left: 0;
	      bottom: 0;
	      right: 0;
	      color: $color-link !important;
				font-weight: 800;
				@include pux-static-size("font-size", 13px)
				text-transform: uppercase;
				letter-spacing: 0.09rem;
				@include media(xxl) {
						letter-spacing: 0.075rem;
				}
				&.submit,
				&[data-function="submit"],
				&.file {
						input {
								width: auto;
								border: 0;
								background-color: transparent;
								color: $color-link !important;
								@include pux-static-size("font-size", 13px);
								font-weight: 800;
								text-transform: uppercase;
								letter-spacing: 0.09rem;
						}
				}
	  }
	  &:focus,
	  &:hover {
				border: 0 !important;
				outline: 0 !important;
				box-shadow: none !important;
	      .btn-border {
	          @include pux-static-size("stroke-dasharray", 133px, 4px, 156px, 4px, 500px, 4px);
	      }
	  }
		&.btn-transition {
				svg {
						@include transition;
				}
		}
}


.btn-mini {
  	svg {
		    display: block;
				@include media(lg) {
						@include pux-static-size("width", 110px);
						@include pux-static-size("height", 30px);
				}
	  }
	  .btn-border {
				@include pux-static-size("stroke-dasharray", 53px, 4px, 136px, 4px,  5000px, 4px);
	  }
	  &:focus,
	  &:hover {
	      .btn-border {
					  @include pux-static-size("stroke-dasharray", 123px, 4px, 136px, 4px, 5000px, 4px);
	      }
	  }
}

.btn-normal {
  	svg {
		    display: block;
				@include pux-static-size("width", 200px);
				@include pux-static-size("height", 50px);
	  }
	  .btn-border {
				@include pux-static-size("stroke-dasharray", 98px, 4px, 246px, 4px, 5000px, 4px);
	  }
	  &:focus,
	  &:hover {
	      .btn-border {
						@include pux-static-size("stroke-dasharray", 223px, 4px, 246px, 4px, 5000px, 4px);
	      }
	  }
}

.btn-large {
  	svg {
		    display: block;
				@include pux-static-size("width", 240px);
				@include pux-static-size("height", 50px);
	  }
	  .btn-border {
	     	@include pux-static-size("stroke-dasharray", 118px, 4px, 286px, 4px, 5000px, 4px);
	  }
	  &:focus,
	  &:hover {
	      .btn-border {
						@include pux-static-size("stroke-dasharray", 263px, 4px, 286px, 4px, 5000px, 4px);
	      }
	  }
}

.btn-form {
  	svg {
		    display: block;
				@include pux-static-size("width", 140px);
				@include pux-static-size("height", 40px);
	  }
		.btn-text {
				color: $main-color !important;
		}
	  .btn-border {
				stroke: $main-color;
	      @include pux-static-size("stroke-dasharray", 68px, 4px, 178px, 4px, 5000px, 4px);
	  }
	  &:focus,
	  &:hover {
	      .btn-border {
						@include pux-static-size("stroke-dasharray", 158px, 4px, 176px, 4px, 500px, 4px);
	      }
	  }
}


.request-form-file {
		.btn-text,
		label {
				color: $main-color !important;
		}
		.btn-border {
				stroke: $main-color !important;
		}
}


.btn-default{
  background: $btn-default-background;
  color: $btn-default-color;
  &:focus,
  &:hover{
      text-decoration: none;
      background: darken($btn-default-background, 5);
      color: $btn-default-color;
  }
}

.image-top-center{
  position: top center !important;
}

.image-top-left{
  position: top left !important;
}

.image-top-right{
  position: top left !important;
}

.image-bottom-center{
  position: bottom center !important;
}

.image-bottom-left{
  position: bottom left !important;
}

.image-bottom-right{
  position: bottom right !important;
}

ul,
li{
		margin:0;
		padding:0;
		list-style:none;
}

main{
    min-height:400px;
    ol{
        margin:0;
        padding:0;
        list-style:none;
        counter-reset: my-ol;
        li{
            position:relative;
            margin:0 0 15px 0;
            padding:0 0 0 20px;
            &:before{
                content: counter(my-ol)".";
                counter-increment: my-ol;
                color:$ol-number;
                position:absolute;
                left:0;
                top:3px;
                font-weight:bold;
            }
        }
    }
    ul{
        margin:0;
        padding:0;
        list-style:none;
        li{
          	font-size: 1.6rem;
            margin:0 0 15px 0;
            padding:0 0 0 20px;
						font-size: 15px;
						line-height: 26px;
            position:relative;
						@include media(lg) {
								padding:0 0 0 3.3rem;
								font-size: 1.9rem;
								line-height: 3.2rem;
						}
						@include media(xxl) {
								padding:0 0 0 3rem;
								font-size: 1.7rem;
								line-height: 3rem;
						}
            &:before{
                content:"";
								width: 13px;
								height: 13px;
								position: absolute;
								background-image: url("../img/bullet.svg");
								background-size: cover;
								background-position: center;
                position:absolute;
                top:6px;
                left:0;
								@include media(lg) {
										width: 1.8rem;
										height: 1.8rem;
										top: .8rem;
								}
								@include media(xxl) {
										width: 1.6rem;
										height: 1.6rem;
										top: 0.7rem;

								}
            }
        }
    }
		table{
			width:100%;
			thead {
					border-bottom: 2px solid $table-tr-border;
			}
			tr{
				border-bottom: 1px solid $table-tr-border;
				td{
					padding:10px 15px;
				}
				&:nth-child(even){
					background: $table-even-background;
				}
			}
		}
		.scroll-table {
				@include media(xxs, md) {
						max-width: 100%;
						overflow-x: auto;
						display: block;
				}
				tbody {
						@include media(xxs, md) {
								width: 100%;
								min-width: 950px;
								display: block;
						}
				}
		}
		.scroll-bar {
				overflow-x:scroll;
				overflow-y: hidden;
				@include media(lg) {
						overflow-x: auto;
				}
				&-inner {

				}
		}
    .respo-table{
      table{
        width:100%;
        thead{
          display: none;
          @include media(md){
            display: table-header-group;
          }
          tr{
            border-bottom: 1px solid $table-tr-border;
            th{
              font-weight: bold;
              padding:10px 15px;
            }
          }
        }
        tbody{
          tr{
            display: block;
            border: 1px solid $table-tr-border;
            border-bottom: none;
            margin:0 0 20px 0;
            &:nth-child(even){
              background: $table-even-background;
            }
            @include media(md){
              display: table-row;
              margin: 0;
              border: none;
              border-bottom: 1px solid $table-tr-border;
            }
            td{
              @include flex-block;
              @include justify-content(space-between);
              width:100%;
              border-bottom: 1px solid $table-tr-border;
              @include media(md){
                display: table-cell;
                border: none;
                width:auto;
              }
              &:before{
                  content:attr(data-th);
                  display:block;
                  text-align:left;
                  font-family:$font-family !important;
                  margin:0 15px 0 0;
                  @include media(md){
                      display:none;
                  }
              }
            }
          }
        }
      }
    }
}

.base-table {
		table{
				width:100%;
				tr{
						border: 0 !important;
						td{
								vertical-align: top;
								padding: 0px 0;
								border: 0 !important;
								@include media(md) {
										padding:0px 10px;
										padding-bottom: 3px !important;
								}
								@include media(lg) {
										padding:0px 15px;
										padding-bottom: 5px !important;
								}
								&:first-of-type {
										padding-left: 0px;
										padding-right: 10px;
								}
						}
						&:nth-child(even){
								background: transparent !important;
						}
				}
		}
}

.flex-box{
    .row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    .col-1 {
        -ms-flex: 0 0 8.333333%;flex: 0 0 8.333333%;-webkit-flex: 0 0 8.333333%; -moz-flex: 0 0 8.333333%;
        max-width: 8.333333%;
      }

      .col-2 {
        -ms-flex: 0 0 16.666667%;flex: 0 0 16.666667%;-webkit-flex: 0 0 16.666667%; -moz-flex: 0 0 16.666667%;
        max-width: 16.666667%;
      }

      .col-3 {
        -ms-flex: 0 0 25%;flex: 0 0 25%;-webkit-flex: 0 0 25%; -moz-flex: 0 0 25%;
        max-width: 25%;
      }

      .col-4 {
        -ms-flex: 0 0 33.333333%;flex: 0 0 33.333333%;-webkit-flex: 0 0 33.333333%; -moz-flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }

      .col-5 {
        -ms-flex: 0 0 41.666667%;flex: 0 0 41.666667%;-webkit-flex: 0 0 41.666667%; -moz-flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }

      .col-6 {
        -ms-flex: 0 0 50%;flex: 0 0 50%;-webkit-flex: 0 0 50%; -moz-flex: 0 0 50%;
        max-width: 50%;
      }

      .col-7 {
        -ms-flex: 0 0 58.333333%;flex: 0 0 58.333333%;-webkit-flex: 0 0 58.333333%; -moz-flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }

      .col-8 {
        -ms-flex: 0 0 66.666667%;flex: 0 0 66.666667%;-webkit-flex: 0 0 66.666667%; -moz-flex: 0 0 66.666667%;
        max-width: 66.666667%;
      }

      .col-9 {
        -ms-flex: 0 0 75%;flex: 0 0 75%;-webkit-flex: 0 0 75%; -moz-flex: 0 0 75%;
        max-width: 75%;
      }

      .col-10 {
        -ms-flex: 0 0 83.333333%;flex: 0 0 83.333333%;-webkit-flex: 0 0 83.333333%; -moz-flex: 0 0 83.333333%;
        max-width: 83.333333%;
      }

      .col-11 {
        -ms-flex: 0 0 91.666667%;flex: 0 0 91.666667%;-webkit-flex: 0 0 91.666667%; -moz-flex: 0 0 91.666667%;
        max-width: 91.666667%;
      }

      .col-12 {
        -ms-flex: 0 0 100%;flex: 0 0 100%;-webkit-flex: 0 0 100%; -moz-flex: 0 0 100%;
        max-width: 100%;
      }

  @media (min-width: 576px) {

  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;flex: 0 0 8.333333%;-webkit-flex: 0 0 8.333333%; -moz-flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;flex: 0 0 16.666667%;-webkit-flex: 0 0 16.666667%; -moz-flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;flex: 0 0 25%;-webkit-flex: 0 0 25%; -moz-flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;flex: 0 0 33.333333%;-webkit-flex: 0 0 33.333333%; -moz-flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
   -ms-flex: 0 0 41.666667%;flex: 0 0 41.666667%;-webkit-flex: 0 0 41.666667%; -moz-flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;flex: 0 0 50%;-webkit-flex: 0 0 50%; -moz-flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;flex: 0 0 58.333333%;-webkit-flex: 0 0 58.333333%; -moz-flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;flex: 0 0 66.666667%;-webkit-flex: 0 0 66.666667%; -moz-flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;flex: 0 0 75%;-webkit-flex: 0 0 75%; -moz-flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;flex: 0 0 83.333333%;-webkit-flex: 0 0 83.333333%; -moz-flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;flex: 0 0 91.666667%;-webkit-flex: 0 0 91.666667%; -moz-flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;flex: 0 0 100%;-webkit-flex: 0 0 100%; -moz-flex: 0 0 100%;
    max-width: 100%;
  }
  }
  @media (min-width: 768px) {

  .col-md-1 {
    -ms-flex: 0 0 8.333333%;flex: 0 0 8.333333%;-webkit-flex: 0 0 8.333333%; -moz-flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;flex: 0 0 16.666667%;-webkit-flex: 0 0 16.666667%; -moz-flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;flex: 0 0 25%;-webkit-flex: 0 0 25%; -moz-flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;flex: 0 0 33.333333%;-webkit-flex: 0 0 33.333333%; -moz-flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;flex: 0 0 41.666667%;-webkit-flex: 0 0 41.666667%; -moz-flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;flex: 0 0 50%;-webkit-flex: 0 0 50%; -moz-flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;flex: 0 0 58.333333%;-webkit-flex: 0 0 58.333333%; -moz-flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;flex: 0 0 66.666667%;-webkit-flex: 0 0 66.666667%; -moz-flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;flex: 0 0 75%;-webkit-flex: 0 0 75%; -moz-flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;flex: 0 0 83.333333%;-webkit-flex: 0 0 83.333333%; -moz-flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;flex: 0 0 91.666667%;-webkit-flex: 0 0 91.666667%; -moz-flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;flex: 0 0 100%;-webkit-flex: 0 0 100%; -moz-flex: 0 0 100%;
    max-width: 100%;
  }}
  @media (min-width: 992px) {

  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;flex: 0 0 8.333333%;-webkit-flex: 0 0 8.333333%; -moz-flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;flex: 0 0 16.666667%;-webkit-flex: 0 0 16.666667%; -moz-flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;flex: 0 0 25%;-webkit-flex: 0 0 25%; -moz-flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;flex: 0 0 33.333333%;-webkit-flex: 0 0 33.333333%; -moz-flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;flex: 0 0 41.666667%;-webkit-flex: 0 0 41.666667%; -moz-flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;flex: 0 0 50%;-webkit-flex: 0 0 50%; -moz-flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;flex: 0 0 58.333333%;-webkit-flex: 0 0 58.333333%; -moz-flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;flex: 0 0 66.666667%;-webkit-flex: 0 0 66.666667%; -moz-flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;flex: 0 0 75%;-webkit-flex: 0 0 75%; -moz-flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;flex: 0 0 83.333333%;-webkit-flex: 0 0 83.333333%; -moz-flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;flex: 0 0 91.666667%;-webkit-flex: 0 0 91.666667%; -moz-flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;flex: 0 0 100%;-webkit-flex: 0 0 100%; -moz-flex: 0 0 100%;
    max-width: 100%;
  }}

  @media (min-width: 1200px) {
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;flex: 0 0 8.333333%;-webkit-flex: 0 0 8.333333%; -moz-flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;flex: 0 0 16.666667%;-webkit-flex: 0 0 16.666667%; -moz-flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;flex: 0 0 25%;-webkit-flex: 0 0 25%; -moz-flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;flex: 0 0 33.333333%;-webkit-flex: 0 0 33.333333%; -moz-flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;flex: 0 0 41.666667%;-webkit-flex: 0 0 41.666667%; -moz-flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;flex: 0 0 50%;-webkit-flex: 0 0 50%; -moz-flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;flex: 0 0 58.333333%;-webkit-flex: 0 0 58.333333%; -moz-flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;flex: 0 0 66.666667%;-webkit-flex: 0 0 66.666667%; -moz-flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;flex: 0 0 75%;-webkit-flex: 0 0 75%; -moz-flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;flex: 0 0 83.333333%;-webkit-flex: 0 0 83.333333%; -moz-flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;flex: 0 0 91.666667%;-webkit-flex: 0 0 91.666667%; -moz-flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;flex: 0 0 100%;-webkit-flex: 0 0 100%; -moz-flex: 0 0 100%;
    max-width: 100%;
  }}
}


@media (min-width: 1500px) {
.col-xxxl-1 {
	-ms-flex: 0 0 8.333333%;flex: 0 0 8.333333%;-webkit-flex: 0 0 8.333333%; -moz-flex: 0 0 8.333333%;
	max-width: 8.333333%;
}
.col-xxxl-2 {
	-ms-flex: 0 0 16.666667%;flex: 0 0 16.666667%;-webkit-flex: 0 0 16.666667%; -moz-flex: 0 0 16.666667%;
	max-width: 16.666667%;
}
.col-xxxl-3 {
	-ms-flex: 0 0 25%;flex: 0 0 25%;-webkit-flex: 0 0 25%; -moz-flex: 0 0 25%;
	max-width: 25%;
}
.col-xxxl-4 {
	-ms-flex: 0 0 33.333333%;flex: 0 0 33.333333%;-webkit-flex: 0 0 33.333333%; -moz-flex: 0 0 33.333333%;
	max-width: 33.333333%;
}
.col-xxxl-5 {
	-ms-flex: 0 0 41.666667%;flex: 0 0 41.666667%;-webkit-flex: 0 0 41.666667%; -moz-flex: 0 0 41.666667%;
	max-width: 41.666667%;
}
.col-xxxl-6 {
	-ms-flex: 0 0 50%;flex: 0 0 50%;-webkit-flex: 0 0 50%; -moz-flex: 0 0 50%;
	max-width: 50%;
}
.col-xxxl-7 {
	-ms-flex: 0 0 58.333333%;flex: 0 0 58.333333%;-webkit-flex: 0 0 58.333333%; -moz-flex: 0 0 58.333333%;
	max-width: 58.333333%;
}
.col-xxxl-8 {
	-ms-flex: 0 0 66.666667%;flex: 0 0 66.666667%;-webkit-flex: 0 0 66.666667%; -moz-flex: 0 0 66.666667%;
	max-width: 66.666667%;
}
.col-xxxl-9 {
	-ms-flex: 0 0 75%;flex: 0 0 75%;-webkit-flex: 0 0 75%; -moz-flex: 0 0 75%;
	max-width: 75%;
}
.col-xl-10 {
	-ms-flex: 0 0 83.333333%;flex: 0 0 83.333333%;-webkit-flex: 0 0 83.333333%; -moz-flex: 0 0 83.333333%;
	max-width: 83.333333%;
}
.col-xxxl-11 {
	-ms-flex: 0 0 91.666667%;flex: 0 0 91.666667%;-webkit-flex: 0 0 91.666667%; -moz-flex: 0 0 91.666667%;
	max-width: 91.666667%;
}
.col-xxxl-12 {
	-ms-flex: 0 0 100%;flex: 0 0 100%;-webkit-flex: 0 0 100%; -moz-flex: 0 0 100%;
	max-width: 100%;
}
}

.Gecko11,
.Gecko10,
.Gecko9,
.Gecko8,
.Gecko7,
.Gecko6,
.Safari11,
.Safari10,
.Safari9,
.Safari8 {
	.btn {
			svg {
					width: 110px !important;
					height: 50px !important;
			}
			&-border {
					stroke-dasharray: 53 4 156 4 5000 4;
			}
			&:focus,
			&:hover {
					.btn-border {
							stroke-dasharray: 133 4 156 4 500 4;
					}
			}
	}
	.btn-mini {
			svg {
					@include media(lg) {
							width: 110px !important;
							height: 30px !important;
					}
			}
			.btn-border {
					stroke-dasharray: 53 4 136 4 5000 4;
			}
			&:focus,
			&:hover {
					.btn-border {
							stroke-dasharray: 123 4 136 4 5000 4;
					}
			}
	}
	.btn-normal {
			svg {
					display: block;
					width: 200px !important;
					height: 50px !important;
			}
			.btn-border {
					stroke-dasharray: 98 4 246 4 5000 4;
			}
			&:focus,
			&:hover {
					.btn-border {
							stroke-dasharray: 223 4 246 4 5000 4;
					}
			}
	}
	.btn-large {
			svg {
					width: 240px !important;
					height: 50px !important;
			}
			.btn-border {
					stroke-dasharray: 118 4 286 4 5000 4;
			}
			&:focus,
			&:hover {
					.btn-border {
							stroke-dasharray: 263 4 286 4 5000 4
					}
			}
	}

	.btn-form {
			svg {
					width: 140px !important;
					height: 40px !important;
			}
			.btn-border {
					stroke-dasharray: 68 4 178 4 5000 4;

			}
			&:focus,
			&:hover {
					.btn-border {
							stroke-dasharray: 158 4 176 4 500 4;
					}
			}
	}

}

a[href*=".jpg"]:not(.widget-gallery-item):not(.gallery-fancy),
a[href*=".JPG"]:not(.widget-gallery-item):not(.gallery-fancy),
a[href*=".png"]:not(.widget-gallery-item):not(.gallery-fancy),
a[href*=".PNG"]:not(.widget-gallery-item):not(.gallery-fancy),
a[href*=".doc"],
a[href*=".DOC"],
a[href*=".xml"],
a[href*=".XML"],
a[href*=".xls"],
a[href*=".XLS"],
a[href*=".pdf"],
a[href*=".PDF"] {
		display: inline-flex;
		@include align-items(center);
		&:before {
				content: "\e901";
				display: inline-block;
				font-size: 100%;
				margin-right: 3px;
				color: $color-link;
				@include transition;
				@include media(md) {
						margin-right: 5px;
				}
				@include media(lg) {
						margin-right: 0.9rem;
				}
				@include media(xxl) {
						margin-right: 0.7rem;
				}
		}
		&:hover,
		&:focus {
				&:before {
						color: $main-color;
				}
		}
}
