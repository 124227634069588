
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.form-container {
		max-width: 100%;
		width: 100%;
		position: relative;
		padding: 0 15px;
		margin-bottom: 6rem;
		@include media(md) {
				margin: 0 auto;
				max-width: $container-max-width;
				width: 100%;
				padding: 0;
		}
}

@keyframes form-step-cover {
		0% { opacity: .8}
		99% { opacity: 0;}
		100% {
			opacity: 0;
			z-index: -1;}
}


.request-form {
		overflow: pux-hidden;
		&-step {
				padding: 20px 10px;
				border: solid $color-line;
				@include pux-static-size("border-width", 2px);
				position: relative;
				opacity: 1;
				@include transition(750ms);
				@include media(md) {
						padding: 4rem 8rem;
				}
				@include media(xxl) {
						padding: 4rem 6.5rem;
				}
				&:after {
						@include transition(750ms);
						opacity: 0;
						z-index: 99;
				}
				&:not(:last-of-type) {
						margin-bottom: 10px;
				}
				h3 {
						margin-bottom: 3rem;
				}
				&-bottom {
						@include flex-block;
						@include flex-direction(column);
						margin-top: 20px;
						@include media(sm) {
								margin-top: 4rem;
								@include flex-direction(row);
								@include justify-content(space-between);
						}
						&-left {
								@include flex(0 1 auto);
								@include flex-block;
								@include flex-direction(column);
								@include align-items(center);
								@include media(sm) {
										@include flex-direction(row);
										@include justify-content(flex-start);
								}
								a {
										margin-top: 15px;
										@include media(sm) {
												margin-top: 0px;
												margin-right: 4rem;
										}
								}
						}
						&-right {
								@include flex-block;
								@include justify-content(center);
								margin-top: 10px;
								@include media(sm) {
										@include flex(0 0 100px);
										@include align-items(center);
										@include justify-content(flex-end);
										margin-top: 0;
								}
								@include media(xxl) {
										@include flex(0 0 10rem);
								}
						}
				}
				&-text {
						margin-top: 3rem;
				}
				&-tabs {
						margin-top: 3rem;
						&-nav {
								position: relative;
								@include flex-block;
								@include flex-wrap(wrap);
								@include media(md) {
										@include flex-wrap(nowrap);
								}
								&:after {
										position: absolute;
										bottom: 0px;
										content: "";
										width: 100%;
										@include pux-static-size("height", 2px);
										display: none;
										background-color: $color-light-gray;
										@include media(md) {
												display: block;
										}
								}
								&-item {
										@include flex-block;
										@include justify-content(center);
										@include align-items(center);
										border-color: $main-color;
										width: 100%;
										@include pux-static-size("border-top-width", 2px);
										@include pux-static-size("border-left-width", 2px);
										@include pux-static-size("border-right-width", 2px);
										@include pux-static-size("border-bottom-width", 2px);
										border-style: solid;
										@include pux-static-size("min-height", 50px);
										text-transform: uppercase;
										font-weight: 800;
										@include pux-static-size("padding", 10px);
										color: $main-color;
										@include pux-static-size("font-size", 13px);
										cursor: pointer;
										background-color: $base-white;
										@include transition;
										text-align: center;
										@include media(xs) {
												@include calc("width", "50% - 2.5px");
										}
										@include media(md) {
												@include pux-static-size("border-top-width", 2px);
												@include pux-static-size("border-left-width", 2px);
												@include pux-static-size("border-right-width", 2px);
												@include pux-static-size("border-bottom-width", 0px);
												@include pux-static-size("width", 270px);
												margin-bottom: 0px;
										}
										&:not(:last-of-type) {
												margin-bottom: 5px;
												@include media(xs) {
													margin-right: 5px;
												}
												@include media(md) {
														margin-bottom: 0;
												}
										}
										&:nth-of-type(2n + 2) {
												@include media(xs, sm) {
													margin-bottom: 5px;
													margin-right: 0px;
												}
										}
										&:hover,
										&:focus {
												color: darken($main-color, 10);
												border-color: darken($main-color, 10);
										}
										&.active {
												z-index: 9;
												border-color: $color-light-gray;
												color: $base-black;
										}
								}
						}
						&-container {
								margin: 4rem 0;
						}
						&-item {
								&:not(:first-of-type) {
										display: none;
								}
						}
				}
				a[data-function="reopen"] {
						display: none;
						z-index: 100;
						opacity: 0;
						@include transition(400ms);
						&.open {
								display: block;
								opacity: 1;
						}
				}
				&.pux-hidden {
						opacity: 1;
						&:after {
								content: "";
								display: block;
								position: absolute;
								top: -4px;
								left: -4px;
								bottom: -4px;
								right: -4px;
								background-color: $base-white;
								z-index: 99;
								opacity: .8;
						}
				}
				&.open {
						opacity: 1;
						&:after {
								content: "";
								display: block;
								position: absolute;
								top: -4px;
								left: -4px;
								bottom: -4px;
								right: -4px;
								background-color: $base-white;
								z-index: 99;
								opacity: .8;
								animation: 300ms form-step-cover forwards;
						}
				}
		}
		&-file {
				display: none;
				margin: 1.5rem 0;
				&-cont {
						@include flex-block;
						@include flex-flow(column);
						margin-top: 1.5rem;
				}
				&-name {
						display: inline-block;
						margin-left: 2rem;
						@include pux-static-size("font-size", 13px);
						font-weight: 600;
				}
				&.active {
						display: block;
				}
				&.full {
						order: -1;
						.btn-remove {
								display: inline-block;
						}
				}
				.btn-remove {
						display: none;
						cursor: pointer;
						@include pux-static-size("padding", 10px);
						&:before {
								content: "\e905";
								display: inline-block;
								color: $main-color;
								@include pux-static-size("font-size", 15px);
								position: relative;
								top: 2px;
						}
						&:hover,
						&:focus {
								&:before {
										color: darken(red, 10);
								}
						}
				}
		}
		&-single-frame {
				padding: 4rem 8rem;
				margin-right: -10px;
				margin-left: -10px;
				border-top: solid $color-line;
				@include pux-static-size("border-width", 2px);
				@include media(md) {
						margin-left: -6.5rem;
						margin-right: -6.5rem;
				}
				&#semifinished {
						@include flex-block;
						@include flex-direction(column);
						@include align-items(center);
						@include media(md) {
								@include flex-direction(row);
						}
						 & > div {
							 	&:first-of-type {
										@include media(md) {
												@include flex(0 1 200px);
										}
								}
								&:last-of-type {
										display: none;
										width: 100%;
										margin-top: 10px;
										@include media(md) {
												@include flex(0 1 400px);
												margin: 0;
												padding-left: 5rem;
												@include pux-static-size("max-width", 400px);
										}
								}
								&.show {
										display: block;
								}
						}

				}
		}
		&-recaptcha {
				text-align: center;
				margin-top: 4rem;
				& > * {
					display: inline-block;
				}
		}
		&-submit {
				text-align: center;
				margin-top: 3rem;
		}
}

.InfoLabel {
		display: block;
		padding: 20px 10px;
		@include media(md) {
				padding: 15rem 8rem;
		}
}

.ErrorLabel {
		display: block;
		padding: 20px 10px;
		@include media(md) {
				padding: 2rem 8rem;
		}
}

.form-product {
		@include flex-block;
		border: solid $color-light-gray;
		@include pux-static-size("border-width", 2px);
		margin-bottom: 3rem;
		@include calc("height", "100% - 3rem");
		@include flex-direction(column);
		@include media(sm) {
				@include flex-direction(row);
		}
		@include media(md) {
				@include flex-direction(column);
		}
		@include media(xxl) {
				@include flex-direction(row);
		}
		&-image {
				@include flex-block;
				@include justify-content(center);
				padding: 20px;
				padding-bottom: 5px;
				@include media(sm) {
						@include flex(0 0 115px);
						max-width: 115px;
						padding: 0;
						padding-top: 80px;
						border-right: solid $color-light-gray;
						@include pux-static-size("border-width", 2px);
				}
				@include media(md) {
						@include flex(0 1 auto);
						max-width: none;
						padding: 20px;
						padding-bottom: 5px;
						border-right-width: 0;
				}
				@include media(xxl) {
						@include flex(0 0 11.5rem);
						max-width: 11.5rem;
						padding: 0;
						padding-top: 80px;
						@include pux-static-size("border-width", 2px);
				}
				img {
						max-width: 80%;
						max-height: 80px;
				}
		}
		&-content {
				@include flex(1 1 auto);
				padding: 1.6rem 4rem 2rem;
				&-text {
						font-weight: 800;
						@include pux-static-size("margin-bottom", 16px);
						text-align: center;
						@include media(sm) {
								text-align: left;
						}
						@include media(md) {
								text-align: center;
						}
						@include media(xxl) {
								text-align: left;
						}
				}
				&-input-item {
						@include flex-block;
						margin-bottom: 5px;
						label {
								@include flex(0 0 90px);
								@include pux-static-size("max-width", 90px);
								@include pux-static-size("font-size", 13px);
								padding-right: 5px;
								@include media(sm) {
										@include flex(0 0 100px);
										max-width: 100px;
								}

								@include media(xxl) {
										@include flex(0 0 10rem);
										max-width: 10rem;
								}
						}
						input {
								@include flex(1 1 100%);
								@include calc("max-width", "100% - 90px");
								@include media(sm) {
										@include calc("max-width", "100% - 90px");
								}
								@include media(xxl) {
										@include calc("max-width", "100% - 10rem");
								}
						}
				}
				&-bottom {
						text-align: right;
				}
		}

}

.title-half-step {
		 margin-top: 30px;
		 @include media(md) {
			 	margin-top: 0;
		 }
}

.selected-products-item {
		padding: 1rem 2rem;
		border: solid $main-color;
		@include pux-static-size("border-width", 2px);
		@include pux-static-size("font-size", 13px);
		@include pux-static-size("margin-top", 15px);
		position: relative;
		@include pux-static-size("padding-left", 35px);
		&-delete {
				position: absolute;
				font-size: 0;
				@include pux-static-size("top", 1px);
				left: 0;
				@include pux-static-size("padding", 10px);
				cursor: pointer;
				&:before {
						content: "\e905";
						display: inline-block;
						color: $main-color;
						@include transition;
						@include pux-static-size("font-size", 15px);
				}
				&:hover,
				&:focus {
						&:before {
								color: darken(red, 10);
						}
				}
		}
		&-name {
				font-weight: 600;
		}
		div {
			  max-width: 100%;
				overflow-wrap: break-word;
				word-break: break-all;
		}
}

.recaptcha-validation-error {
		text-align: center;
		font-weight: 700;
		color: $error-label-color;
}


.block-form {
		margin: 4rem 0;
		h1 {
				margin-bottom: 3rem;
		}
}

.IE11 {
		.form-product-image {
			 img {
				 	height: 80px !important;
			 }
		}
}
