
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.pux-container{
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    max-width: $container-max-width;
    &:after{
        display: block;
        content: "";
        clear: both;
    }
    @include media(md){
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
}

.pux-container{
    .row{
        @include flex-block;
        @include flex-wrap(wrap);
        margin-left: $row-margin-left;
        margin-right: $row-margin-right;
        @include media(md){
          margin-left: $row-margin-left-md;
          margin-right: $row-margin-right-md;
        }
    }
}

.pux-container{
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto{
        padding-left: $col-padding-left;
        padding-right: $col-padding-right;
        @include media(md){
          padding-left: $col-padding-left-md;
          padding-right: $col-padding-right-md;
        }
    }
}

.pux-fluid-container{
    padding-left: $fluid-container-padding-left;
    padding-right: $fluid-container-padding-right;
    @include media(md){
      padding-left: $fluid-container-padding-left-md;
      padding-right: $fluid-container-padding-right-md;
    }
    @include media(xl){
      padding-left: $fluid-container-padding-left-xl;
      padding-right: $fluid-container-padding-right-xl;
    }
}

@keyframes menu-visible-top {
		0% {
				opacity: 0;
				margin-top: 2rem;
	 	}
		100% {
				opacity: 1;
				margin-top: 0;
		}
}

@keyframes menu-visible-left {
		0% {
			opacity: 0;
			right: -2rem;
	 }
		100% {
			opacity: 1;
			right: -1px;
		}
}

@keyframes menu-visible {
		0% {
			opacity: 0;
	 }
		100% {
			opacity: 1;
		}
}

@keyframes menu-logo-visible {
		0% {
			opacity: .5;
			@include scale(0,0);
	 	}
		100% {
			opacity: 1;
			@include scale(1,1);
		}
}


header{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 999;
		background-color: $base-white;
		.header {
				height: $header-h-small;
				@include flex-block;
				@include align-items(center);
				@include justify-content(space-between);
				@include transition;
				@include media(md) {
						height: $header-h-medium;
				}
				@include media(lg) {
						height: $header-h-large;
				}
				&-logo {
						@include flex(0 1 120px);
					  max-width: 120px;
						@include media(lg) {
								@include flex(0 0 20rem);
								max-width: 20rem;
						}
						img {
								width: auto;
								height: 30px !important;
								@include transition;
								@include media(md) {
										height: 4rem !important;
								}
								@include media(xl) {
										height: 5rem !important;
								}
						}
				}
				&-nav {
						@include media(lg) {
								@include flex(1 1 auto);
								@include flex-block;
								@include justify-content(flex-end);
								height: inherit;
								padding: 0;
								background-color: transparent;
						}
						&-menu-wrapper {
								max-height: calc(100vh - 230px);
								overflow-y: auto;
								padding-right: 20px;
								overflow-x: hidden;
								@include media(lg) {
										max-height: none;
										height: inherit;
										overflow: visible;
										padding: 0;
								}
						}
						&-menu {
								$margin-btwn-link: 5rem;
								$width-sliders: 220px;

								height: inherit;
								text-transform: uppercase;
								font-weight: 800;
								@include flex-flow(column);
								display: none;
								position: absolute;
								top: 0;
								left: 0;
								background-color: rgba($base-black, .9);
								height: 100vh;
								width: 100%;
								padding: 70px 0 80px;
								@include pux-static-font(12px);
								letter-spacing: 0.075rem;
								@include media(lg) {
										position: static;
										@include flex-block;
										@include flex-flow(row);
										@include justify-content(flex-end);
										padding: 0;
										height: 100%;
										background-color: transparent;
								}
								@include media(xxl) {
								}
								.menu,
								& > ul {
										height: inherit;
										@include flex-block;
										@include flex-flow(column);
										@include align-items(flex-end);
										@include media(lg) {
												@include flex-flow(row);
												@include align-items(initial);
										}
										& > li {
												text-align: right;
												padding-right: 30px;
												position: relative;
												@include media(lg) {
														padding-right: $margin-btwn-link;
														text-align: left;
												}
												@include media(xxl) {
														padding-right: 3rem;
												}
												@include breakpointhelper(1600px, 9999px) {
														padding-right: $margin-btwn-link;
												}
												&:first-of-type {
													 	@include media(xxl) {
																padding-right: 0;
														}
												}
												&:last-of-type {
														@include media(lg) {
																padding-right: 0;
														}
												}
												a {
														text-decoration: none !important;
														@include transition;
														color: $main-color;
														display: inline-block;
														position: relative;
														&:focus,
														&:hover {
																color: $main-color;
														}
														&.main-link {
																@include media(lg) {
																		height: 100%;
																		@include flex-block;
																		@include align-items(center);
																		overflow: hidden;
																}
																&:after {
																		position: absolute;
																		content: "";
																		background-color: $color-link;
																		display: none;
																		@include pux-static-size("height", 2px);
																		bottom: 2rem + 0.5rem;
																		width: 100%;
																		opacity: 0;
																		@include transition();
																		@include media(lg) {
																				display: block; 
																		}
																}
																&.active {
																		&:after {
																				@include media(lg) {
																						bottom: 2rem;
																						opacity: 1 !important;
																				}
																		}
																}
																&:focus,
																&:hover {
																		&:after {
																				opacity: 1;
																				bottom: 2rem;
																		}
																}
														}
												}
												span {
														color: $main-color;
												}
										}
								}
								li {
										@include flex-block;
										@include flex-flow(column);
										position: relative;
										height: inherit;
										@include media(lg) {
												@include flex-flow(row);
												@include align-items(center);
										}
										a {
												@include media(xxs, md) {
													 	font-size: 16px;
														padding: 10px 0 !important;
												}
										}
										&:hover,
										&:focus {
												& > .menu-level-half {
															@include media(lg) {
																	display: block;
																	animation: menu-visible-top .3s forwards;
															}
															@include media(xxl) {
																	@include flex-block;
																	animation: none;
															}
												}
												& > .menu-level-one {
														&:before {
																content: "";
																display: block;
																position: absolute;
																height: 100%;
																width: 10px;
																top: 0;
																left: -10px;
														}
														@include media(lg) {
																display: block;
																animation: menu-visible-left .3s forwards;
														}
														@include media(xxl) {
																display: block;
																animation: menu-visible-top .3s forwards;
														}
												}
												& > .menu-base {
														@include media(lg) {
																display: block;
																animation: menu-visible-top .3s forwards;
														}
												}
										}
										&.menu-item-level-half {
												@include media(lg) {
														height: 100%;
														margin-right: 0;
												}
												@include media(xxl) {
														height: inherit;
												}
										}
										&.menu-item-level-second {
												height: auto;
												margin-right: 0;
										}
										.menu-no-hover {
												display: none;
												@include media(lg) {
														display: block;
												}
												@include media(xxl) {
														display: none;
												}

										}
								}
								.menu-level-half,
								.menu-level-one {
										a {
												display: block;
												text-transform: none;
												padding: 10px 0;
										}
								}
								.menu-level-half {
										@include media(lg) {
												display: none;
												z-index: 0;
												padding: 2rem 0;
												position: absolute;
												top: $header-h-large;
												background-color: rgba($main-color, .98);
												color: $base-white;
												width: $width-sliders;
												left: 0;
												font-size: rem(lg, 14px);
												@include transition();
												.main-link {
														height: auto;
														&:after {
																display: none !important;
														}
												}
										}
										@include media(xl) {
												font-size: rem(xl, 14px);
										}
										@include media(xxl) {
												position: static;
												height: inherit;
												background-color: transparent;
												z-index: 1;
												color: $second-color;
												@include flex-block;
												@include align-items(center);
												width: auto;
												left: auto;
												transform: none;
											  @include transition(none);
												padding: 0;
												font-size: rem(xxl, 12px);
												.main-link {
														height: 100%;
														&:after {
																display: block !important;
														}
												}
										}
										& > li {
												position: relative;
												@include media(lg) {
														position: static;
														padding: 0 2rem;
														@include flex-block;
														@include justify-content(space-between);
												}
												@include media(xxl) {
														position: relative;
														padding-left: 0 !important;
														padding-right: 3rem;
												}
												@include breakpointhelper(1600px, 9999px) {
														padding-right: $margin-btwn-link;
												}
												a {
														color: $main-color;
														text-transform: uppercase;
														@include media(lg) {
																text-transform: none;
																color: $base-white;
														}
														@include media(xxl) {
																color: $main-color;
																text-transform: uppercase;
														}
														&:focus,
														&:hover {
																@include media(lg) {
																		color: $color-link;
																}
																@include media(xxl) {
																		color: $main-color;
																}
														}
												}
												.menu-touch {
														&:after {
																@include media(lg) {
																		color: $base-white;
																		@include rotate(-90deg);
																}
																@include media(xxl) {
																		color: $main-color;
																		@include rotate(0deg);
																}
														}
												}
										}
								}
								.menu-level-one {
										display: none;
										@include media(lg) {
												width: $width-sliders;
												background-color: rgba($main-color, .98);
												color: $base-white;
												padding: rem(lg, 20px, 0px);
												position: absolute;
												top: 0;
												right: 0;
												opacity: 0;
												@include translate(100%, 0);
												min-height: 100%;
												@include transition(300ms);
												font-size: rem(lg, 14px);
										}
										@include media(xl) {
												font-size: rem(xl, 14px);
										}
										@include media(xxl) {
												display: none;
												top: $header-h-large;
												left: 0;
												right: auto;
												transform: none;
												min-height: 0;
												opacity: 1;
												font-size: rem(xxl, 14px);
										}
										li {
												display: block;
												@include media(lg, xxl) {
														padding: rem(xl, 0px, 20px);
												}
												a {
														color: $base-white;
														text-transform: none;
														&:focus,
														&:hover {
																color: $color-link;
														}
												}
										}
								}
								.menu-base {
										@include media(lg) {
												display: none;
												position: absolute;
												top: $header-h-large;
												background-color: $main-color;
												color: $base-white;
												transform: none;
												right: auto;
												left: 0;
										}
								}
						}
						&-right-side {
								position: fixed;
								display: none;
								bottom: 0px;
								left: 0px;
								width: 100%;
								z-index: 99;
								@include pux-static-size("padding", 15px);
								@include media(lg) {
										position: static;
										@include flex(1 0 24rem);
										@include flex-block;
										@include justify-content(flex-end);
										@include align-items(center);
										padding: 0;
										z-index: 1;
								}
								@include media(xxl) {
										@include flex(1 0 21rem);
								}
								.language-selector {
										position: relative;
										min-width: 20px;
										@include flex-inline;
										@include align-items(center);
										@include pux-static-size("margin-left", 20px);
										@include pux-static-size("margin-right", 10px);
										order: -1;
										display: none !important;
										@include media(lg) {
												@include flex-block;
												width: auto;
												margin-left: rem(lg, 30px);
												margin-right: 0px;
												order: 2;
										}
										ul {
												margin: 0;
												padding: 0;
												list-style: none;
												@include flex-block;
												@include media(lg) {
														@include flex-direction(column);
												}
												li {
														margin: 0;
														padding: 0;
														list-style: none;
														display: inline-block;
														@include media(lg) {
																display: inline-block;
														}
														&:not(:last-of-type) {
																padding-bottom: 2px;
														}
												}
										}
										.header-language-current {
												margin-right: 10px;
												@include media(lg) {
														margin-right: 0;
												}
										}
										.header-language-inner {
												position: relative;
												display: block;
												@include transition();
												@include media(lg) {
														position: absolute;
														top: 5.1rem;
														right: -8px;
														opacity: 0;
														padding: 0;
														background-color: $base-white;
														border: 1px solid $main-color;
														display: none;
												}
												&.open {
														display: block;
														animation: menu-visible .3s forwards;
												}
												.active {
														display: none !important;
														@include media(lg) {
																display: block !important;
														}
												}
												.cs-cz,
												.en-us,
												.de-de {
														margin: 10px;
														margin-left: 20px;
														display: block;
														@include media(lg) {
															 margin: 7px;
														}
												}
										}
										.cs-cz,
										.en-us,
										.de-de {
												display: block;
												font-size: 0;
												@include pux-static-size("width", 20px);
												@include pux-static-size("height", 20px);
												background-size: 100% 100%;
												cursor: pointer;
												&:before {
														content: "";
														display: block;
														width: 34px;
														height: 28px;
														position: relative;
														top: -8px;
						    						left: -8px;
												}
										}
										.cs-cz {
												background-image: url('/Dare/media/system/svg/header-flag.svg');
										}
										.en-us {
												background-image: url('/Dare/media/system/svg/flag-rounded-gb.svg');
										}
										.de-de {
												background-image: url('/Dare/media/system/svg/flag-rounded-german.svg');
										}
								}
						}
						.mobile-menu-logo {
							 width: 30px;
							 height: 30px;
							 position: absolute;
							 opacity: 0;
							 top: 20px;
							 left: 20px;
							 display: none;
							 @include media(lg) {
									display: none !important;
							 }
						}
				}
				&.open {
						.header-nav-menu {
								@include media(xxs, md) {
										max-width: 100%;
										overflow: hidden;
										display: block;
								}
						}
						.mobile-menu-logo {
								@include media(xxs, md) {
										display: block;
										animation: menu-logo-visible 500ms forwards;
										animation-delay: 200ms;
								}
						}
						.header-nav-right-side {
								@include media(xxs, md) {
										display: flex;
										@include justify-content(space-between);
								}
						}
				}

		}
		&.small {
				.header {
						@include media(md) {
								height: $header-h-medium - 20px;
						}
						@include media(lg) {
								height: $header-h-large - 3rem;
						}
						&-logo {
								img {
										@include media(md) {
												height: 25px !important;
										}
										@include media(lg) {
												height: 3.5rem !important;
										}
								}
						}
						.menu-level-half {
								@include media(md) {
										top: $header-h-medium - 20px;
								}
								@include media(lg) {
										top: $header-h-large - 3rem;
								}
						}
						.menu-level-one {
								@include media(xxl) {
										top: $header-h-large - 3rem;
								}
						}
						.menu-base {
								@include media(lg) {
										top: $header-h-large - 3rem;
								}
								@include media(xxl) {
										top: $header-h-large - 3rem;
								}
						}
						.main-link {
								&:after {
										bottom: 1.25rem !important;
								}
								&:hover,
								&:focus {
										&:after {
											bottom: 1rem !important;
										}
								}
								&.active {
										@include media(lg) {
												&:after {
														bottom: 1rem !important;
												}
										}
								}
						}
				}
				.header-language-inner {
						@include media(lg) {
								top: 3.5rem !important;
						}
				}
		}
}

.menu-button {
		position: relative;
		-webkit-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		@include media(lg) {
				display: none;
		}
		&:before{
		  	content: "";
			  position: absolute;
			  -webkit-box-sizing: border-box;
			  -moz-box-sizing: border-box;
			  box-sizing: border-box;
				border: solid transparent;
				width: 40px;
				height: 40px;
				border-width: 3px;
			  top: calc(50% - 20px);
			  left: calc(50% - 20px);
			  border-radius: 100%;
			  -webkit-transition: all 0.3s ease-in-out;
			  -o-transition: all 0.3s ease-in-out;
			  transition: all 0.3s ease-in-out;
		}
		&-line {
				width: 20px;
				height: 3px;
				background-color: $base-black;
				display: block;
				margin: 4px auto;
				-webkit-transition: all 0.3s ease-in-out;
				-o-transition: all 0.3s ease-in-out;
				transition: all 0.3s ease-in-out;
				cursor: pointer;
		}
		&.open {
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				transform: rotate(45deg);
				&:before {
						border-color: $base-white;
				}
				.menu-button-line {
						background-color: $base-white;
						&:nth-child(2){
						  	opacity: 0;
						}
					 	&:nth-child(1){
							  -webkit-transform: translateY(13px);
							  -ms-transform: translateY(13px);
							  -o-transform: translateY(13px);
							  transform: translateY(8px);
						}
						&:nth-child(3){
							  -webkit-transform: translateY(-13px) rotate(90deg);
							  -ms-transform: translateY(-13px) rotate(90deg);
							  -o-transform: translateY(-13px) rotate(90deg);
							  transform: translateY(-6px) rotate(90deg);
						}
				}
		}
}

.btn-submenu {
		width: 0;
		height: 0;
		cursor: pointer;
		position: absolute;
		top: 0;
		right: -40px;
		@include media(lg) {
				display: none;
		}
		&:after {
				content: "\e902";
				@include pux-static-size("font-size", 10px)
				color: $main-color;
				position: absolute;
				right: 0px;
				top: 9px;
				padding: 10px;
				@include transition;
		}
		&.open {
				&:after {
						color: $base-white;
						@include rotate(180deg);
				}
		}
		&:hover,
		&:focus {
				&:after {
						color: $base-white;
				}
		}
		&.submenu-out {
				right: -10px;
		}
}

.EditMode,
.DesignMode {
  header {
    position: static;
  }
}

footer {
		background-color: $second-color;
		padding: 25px 0;
		@include media(lg) {
				padding: 3rem 0;
		}
		@include media(xl) {
				padding: 2.5rem 0;
		}
}

.footer-menu {
		@include flex-block;
		text-align: center;
		@include flex-direction(column);
		@include media(xl) {
				text-align: left;
				@include flex-direction(row);
		}
		&-logo {
				@include media(xl) {
					 	@include flex(0 1 24rem);
						max-width: 24rem;
				}
				img {
					 	height: 30px !important;
						@include media(lg) {
								height: 5rem !important;
						}
				}
		}
		&-links {
				margin-top: 20px;
				@include pux-static-font(13px);
				@include media(sm) {
					margin-top: 25px;
				}
				@include media(md) {
					margin-top: 30px;
				}
				@include media(lg) {
						margin-top: 4rem;
						@include flex(1 1 auto);
				}
				@include media(xl) {
						margin: 0;
				}
				& > div > div > * {
						&:not(:first-of-type) {
								@include media(sm) {
										margin-top: 10px;
								}
								@include media(lg) {
										margin-top: 0.9rem;
								}
								@include media(xxl) {
										margin-top: 0.7rem;
								}
						}
						a {
							display: inline-block;
							text-transform: uppercase;
							font-weight: 800;
							color: $color-link;
							@include transition;
							margin-top: 20px;
							letter-spacing: 0.3rem;
							@include media(sm) {
									margin: 0;
									letter-spacing: 0.2rem;
							}
							@include media(md) {
									margin-top: 10px;
							}
							@include media(lg) {

									letter-spacing: 0.1rem;
							}
							&:hover,
							&:focus {
									border: 0;
									outline: 0;
									color: $main-color;
									text-decoration: none;
							}
					}
				}
		}
		&-about {
				color: $base-white;
				font-weight: 300;
				margin-top: 25px;
				@include media(sm) {
						margin-top: 30px;
				}
				@include media(md) {
						margin-top: 30px;
				}
				@include media(xl) {
						margin: 0;
						@include flex(0 1 30rem);
						max-width: 30rem;
				}
				@include media(xxl) {
						margin: 0;
						@include flex(0 1 20rem);
						max-width: 20rem;
				}
		}
}

.is-xxl{
    display:none;
    @include media(xxl){
        display:block;
    }
}



.block-small {
		margin: 6rem 0;
}

.block-base {
		margin: 7.5rem 0;
}

.block-inner-base {
		margin: 7rem 0;
}

.block-margin-top {
		margin: 7.5rem 0;
}

.block-margin-top-small {
		margin-top: 6rem;
}

.block-mini {
		margin-top: 20px;
		margin-bottom: 20px;
}

.block-padding {
		width: 100%;
		max-width: $container-max-width;
		@include media(md) {
				@include calc("padding-left", "2rem +" $main-left-md);
				@include calc("padding-right", "2rem +" $main-right-md);
		}
}
.center-resp {
		text-align: center;
		@include media(lg) {
				text-align: left;
		}
}

.space-big {
		overflow: hidden;
		.row {
				@include media(xxl) {
						margin-left: -8rem;
						margin-right: -8rem;
						overflow: hidden;
				}
		    [class*="col-"] {
						@include media(xxl) {
								padding-left: 8rem;
								padding-right: 8rem;
						}
		    }
		}
}

.space-normal {
		overflow: hidden;
		.row {
				@include media(md) {
						@include pux-static-size("margin-left", -20px);
						@include pux-static-size("margin-right", -20px);
						overflow: hidden;
				}
		    [class*="col-"] {
						@include media(md) {
								@include pux-static-size("padding-left", 20px);
								@include pux-static-size("padding-left", 20px);
						}
		    }
		}
}



.device-small {
		display: block;
		@include media(md) {
				display: none;
		}
}

.device-medium {
		display: none;
		@include media(md, lg) {
				display: block;
		}
}

.device-large {
		display: none;
		@include media(xl) {
				display: block;
		}
}

.header-padding {
		padding-top: $header-h-small;
		@include media(md) {
				padding-top: $header-h-medium;
		}
		@include media(lg) {
				padding-top: $header-h-large;
		}
}
