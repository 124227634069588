
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

$selectric-main-color:      #c7c7c7 !default;                                              // Color used in border
$selectric-secondary-color: #e0e0e0 !default;                                              // Color used in button
$selectric-text-color:      $color-link !default;                                              // Color used in label
$selectric-bg-color:        #eeeff4 !default;                                           // Background color
$selectric-btn-bg-color:    #000 !default;                                           // Button background color
$selectric-height:          50px !default;                                              // Outer height
$selectric-spacing:         15px !default;                                              // Label left padding
$selectric-border-width:    1px !default;                                               // Outer border width
$selectric-border-radius:   0px !default;                                               // Border radius
$selectric-inner-height:    $selectric-height - ($selectric-border-width * 2) !default; // Inner height
$selectric-font-size:       15px !default;                                              // Font size
$selectric-border-color:    #e0e0e0 !default;
$selectric-gray-color:      #999 !default;

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  &:focus{
    background:#eeeff4;
    color:$selectric-main-color;
    border-bottom:2px solid $selectric-main-color;
  }
}


.selectric-responsive {
  width: 100%;
}

.selectric {
  //border: $selectric-border-width solid $selectric-border-color;
  border-radius: $selectric-border-radius;
  background: $selectric-bg-color;
  position: relative;
  overflow: hidden;
  @include border-radius;
	border-radius: 0;

  .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include pux-static-size("font-size", $selectric-font-size);
		@include pux-static-size("line-height", 50px);
    color: $selectric-text-color;
		border-bottom: solid $border-color;
		@include pux-static-size("border-width", 2px);
	  background: $input-background !important;
    user-select: none;
		border-radius: 0;
		padding: 0 2rem;
		@include transition;
  }

  .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: $selectric-inner-height;
    @include pux-static-size("height", 50px);
    line-height: $selectric-inner-height;
    //background-color: $selectric-btn-bg-color;
    color: $selectric-secondary-color;
    text-align: center;
    font: 0/0 a;
    *font: 20px/#{$selectric-inner-height} Lucida Sans Unicode, Arial Unicode MS, Arial;
    transition: all ease 200ms;

    &:after {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 8px;
      content: "\e902";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $color-link;
			@include media(lg) {
					font-size: .8rem;
			}
    }
  }
}

.selectric-focus .selectric {
  border-color: $selectric-border-color;
}

.selectric-hover .selectric {
  border-color: $selectric-border-color;
}

.selectric-open {
  z-index: 9999;
	.label {
			border-bottom: solid $focus-border-color;
			@include pux-static-size("border-width", 2px);
	}
  .selectric {
    border-color: $selectric-border-color;
    border-bottom: 0px;

    .button {
      color: darken($selectric-secondary-color, 10%);
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
  }

  .selectric-items {
    display: block;
  }
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;

  select {
    position: absolute;
    left: -100%;
  }

  &.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;

    select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0;
    }
  }
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: $base-white;;
  border: 1px solid $selectric-border-color;
  border-top: 0;
  z-index: -1;
  box-shadow: 0 0 10px -6px;

  .selectric-scroll {
    height: 100%;
    overflow: auto;
  }

  .selectric-above & {
    top: auto;
    bottom: 100%;
  }

  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
		@include pux-static-size("font-size", $selectric-font-size);
    line-height: 20px;
    min-height: 20px;
  }

  li {
    display: block;
    padding: 10px;
    color: $selectric-text-color;
    background-color: lighten($color-light-gray, 5);
    cursor: pointer;
    transition: all ease 200ms;
    &:before{
        display:none;
    }
		&:nth-child(odd){
				background-color: $color-light-gray;
		}
    &.selected {
			background: lighten($main-color, 5);
			color: $base-white;
    }

    &.highlighted {
			background: lighten($main-color, 5);
			color: $base-white;
    }

    &:hover {
      background: lighten($main-color, 5);
			color: $base-white;
      //color: $selectric-text-color;
    }
  }

  .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none;
  }

  .selectric-group {
    .selectric-group-label {
      font-weight: bold;
      padding-left: 10px;
      cursor: default;
      user-select: none;
      background: none;
      color: #444;
    }

    &.disabled li {
      filter: alpha(opacity=100);
      opacity: 1;
    }

    li {
      padding-left: 25px;
    }
  }
}
