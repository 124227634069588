
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


label,
.EditingFormLabel{
  display: block;
	@include pux-static-size("margin-top", 10px);
	@include pux-static-size("margin-bottom", 5px);
	@include pux-static-font(15px);
	font-weight: 800;
}

.form-control,
textarea,
input[type="text"],
input[type="password"]{
  width:100%;
	padding: 0;
  @include pux-static-size("height", $input-height);
  @include pux-static-size("padding-left", 15px);
	@include pux-static-size("padding-right", 15px);
	border: 0; 
  border-top: 2px solid $border-color;
	@include pux-static-size("border-width", 2px);
  background: $input-background !important;
	border-radius: 0;
	@include transition;
	@include pux-static-size("font-size", 13px);
  @include placeholder{
    color: $color-placeholder;
  }
  &:focus{
    outline: 0;
    border-top: solid $focus-border-color;
		@include pux-static-size("border-width", 2px);
  }
	&.small {
			height: 40px;
			@include media(xxl) {
					height: 4rem;
			}
	}
}


textarea{
	&.form-control {
				@include pux-static-size("height", $textarea-height);
				@include pux-static-size("padding", 15px);
				resize: none;
				&:focus{
					border-top: solid $focus-border-color;
					@include pux-static-size("border-width", 2px);
				}
				&:-webkit-resizer {
					display: none;
				}
		}
}


input[type="submit"]{
    &:focus,
    &:hover{

    }
}

.required {
    &:after {
        content:"*";
        @include pux-static-font(13px);
        display: inline-block;
        vertical-align: 0.3rem;
        margin: 2px 0 0 2px;
				color: $color-link;
    }

}

.file{
  .uploader-upload{
      display:none;
  }
  label{
      display:inline-block;
      text-transform:uppercase;
      color: $color-link;
      background: transparent;
      cursor:pointer;
      @include pux-static-size("font-size", 13px)
      @include border-radius(0);
			height: 100%;
      margin:0;
      width: 100%;
      text-align:center;
      cursor:pointer;
      letter-spacing:1px;
      outline:0 !important;
			@include flex-block;
			@include align-items(center);
			@include justify-content(center);
  }
  .file-name{
      padding:5px;
      background: $file-name-background;
      color: $file-name-color;
			display: none;
  }
}

@if($use-blink-error == 1){
    .Error{
        select,
        .selectric,
        input[type="text"],
        textarea{
            border-top: solid $error-input-border !important;
						@include pux-static-size("border-top-width", 2px);
            @include animation(normal, blink, 1s, ease, 2);
        }
    }

    @include keyframes(blink){
        0% {
            background: $error-blink-start;
        }
        50% {
            background: $error-blink-final;
        }
        100% {
            background: $error-blink-start;
        }
    }
}

.EditingFormErrorLabel{
    @include pux-static-font(16px);
    color:$error-label-color;
    display:block;
    margin:5px 0 0 0;
}

.ErrorLabel{
    color:$error-label-color;
    display:block;
    margin:5px 10px;
}

.radio{
    input[type="radio"]{
        display:none;
        & +label{
            position:relative;
            padding:0 0 10px 25px;
            display: inline-block;
            margin:0;
            &:before{
                content:"";
                @include border-radius(50%);
                width:16px;
                height:16px;
                background:$radio-background;
                display:block;
                position:absolute;
                left:0;
                top:3px;
                border:1px solid $radio-border;
                @include transition;
            }
            &:after{
                content:"";
                display:block;
                left:5px;
                top:8px;
                width:6px;
                height:6px;
                background:$radio-after-background;
                @include border-radius(50%);
                position:absolute;
                @include opacity(0);
                @include transition;
            }
        }
        &:checked{
            & +label{
                &:before{
                    border:1px solid $radio-active-border;
                }
                &:after{
                    @include opacity(1);
                }
            }
        }
    }
}

.checkbox{
    input[type="checkbox"]{
        display:none;
        & +label{
            position:relative;
						@include pux-static-size("padding-left", 25px);
						@include pux-static-size("padding-bottom", 10px);
						@include pux-static-size("padding-top", 5px);
            margin:0;
            display: inline-block;
						font-weight: 400;
            &:before{
                content:"";
								@include pux-static-size("width", 20px);
								@include pux-static-size("height", 20px);
                background:$checkbox-background;
                display:block;
                position:absolute;
                left:0;
                @include pux-static-size("top", 5px);
                @include transition;
            }
            &:after{
                content:"";
                display:block;
								@include pux-static-size("left", 4px);
								@include pux-static-size("top", 9px);
								@include pux-static-size("width", 12px);
								@include pux-static-size("height", 12px);
                background:$checkbox-after-background;
                position:absolute;
                @include opacity(0);
                @include transition;
            }
        }
        &:checked{
            & +label{
                &:after{
                    @include opacity(1);
                }
            }
        }
    }
}

.form-list {
		.checkbox-list-vertical {
				@include media(lg) {
						display: block;
						columns: 2;
				}
		}
		&.list-long {
				.checkbox-list-vertical {
						@include media(sm) {
								display: block;
								columns: 2;
								column-gap: 50px;
						}
				}
		}
}
