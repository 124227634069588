
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


.top-banner {
		position: relative;
		width: 100%;
		min-height: 100vh;
		overflow: hidden;
		height: 100%;
		max-height: 1500px;
		@include media(sm) {
				min-height: 600px;
				height: 100vh;
		}
		@include media(lg) {
				min-height: 0;
				height: 78rem + $header-h-large;
		}
		&-container {
				position: absolute;
				top: 0;
				left: 0;
				width: inherit;
				min-height: inherit;
				max-height: inherit;
				height: inherit;
				object-position: 50% 50%;
				object-fit: cover;
				overflow: hidden;
				&:after {
						content: "";
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						//background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAE0lEQVQYV2NkYGBoAGFGBghoAAAMrQGDXtzIVgAAAABJRU5ErkJggg==");
						background-image: url('/Dare/media/system/img/pattern.png');
						background-repeat: repeat;
				}
		}
		&-image {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100% !important;
				object-position: 75% 50%;
				object-fit: cover;
		}
		&-fix {
				width: inherit;
				min-height: inherit;
				height: inherit;
				background-size: cover;
				background-position: 75% 50%;
		}
		&-content {
				width: inherit;
				min-height: inherit;
				height: inherit;
				max-height: inherit;
				@include flex-block;
				@include justify-content(center);
				@include align-items(center);
				@include flex-flow(column);
				color: $base-white;
				text-align: center;
				padding: 30px 20px;
				padding-top: $header-h-small + 40px;
				@include media(md) {
						padding-top: $header-h-medium + 40px;
						padding-bottom: 40px;
				}
				@include media(lg) {
						padding: 0;
						padding-top: $header-h-large;
				}
				&-inner {
						@include flex-block;
						@include flex-flow(column);
						z-index: 1;
						@include media(lg) {
								height: 100%;
								@include flex-flow(row, nowrap);
								@include justify-content(space-between);
						}
				}
				&-left {
						@include media(lg) {
								@include flex(1 1 50%);
								max-width: 50%;
								@include flex-block;
								@include justify-content(center);
								@include align-items(flex-start);
								@include flex-flow(column);
						}
				}
				.perex{
						margin-top: 20px;
						font-weight: 300;
				}
				.btn {
						margin-top: 30px;
				}

		}
}

@keyframes loading {
		0% { width: 0%; }
		100% { width: 100%; }
}

.slick-dots {
		@include flex-block;
		@include flex-flow(column);
		@include align-items(center);
		margin-top: 4rem;
		@include media(md) {
				@include flex-flow(row, nowrap);
				@include justify-content(center);
		}
		@include media(lg) {
				@include justify-content(flex-start);
		}
		li {
				position: relative;
				margin: 0;
				padding: 0;
				margin-top: 10px;
				padding-bottom: 10px;
				margin-right: 5px;
				width: 200px;
				cursor: pointer;
				@include transition;
				@include media(md) {
						margin-top: 0px;
				}
				&:before {
						content: "";
						display: block;
						position: absolute;
						bottom: -2px;
						top: auto;
						left: 0;
						width: 100%;
						height: 2px;
						background-color: $color-link;
						z-index: 99;
						border-radius: 0;
						background-image: none !important;
				}
				&:after {
						content: "";
						display: block;
						position: absolute;
						bottom: -2px;
						left: 0;
						width: 0;
						height: 2px;
						background-color: $base-white;
						z-index: 999;
				}
				span {
						color: $color-link;
						text-decoration: none;
						font-weight: 800;
						font-size: 13px;
						text-transform: uppercase;
						letter-spacing: 0.1rem;
						@include transition();
						@include media(xxl) {
								font-size: 1.3rem;
						}
				}
				&.slick-active {
						span {
							 color: $base-white;
						}
						&:after {
								animation: loading 8s linear forwards;
						}
						&.animation-done {
								&:before {
										background-color: $base-white;
								}
						}
				}
		}

}

.content-card {
		@include flex-block;
		@include flex-direction(column);
		@include media(lg) {
				@include flex-direction(row);
		}
		&-item {
				padding: 10rem 0;
				background-size: cover;
				background-position: center;
				color: $base-white;
				@include flex-block;
				@include justify-content(center);
				@include media(lg) {
						@include flex(0 1 50%);
						max-width: 50%;
						@include justify-content(flex-end);
				}
				&-inner {
						width: 90%;
						@include media(md) {
								width: 80%;
						}
						@include media(lg) {
								width: 100%;
								@include flex-block;
								@include justify-content(flex-end);
								@include calc("max-width",  $container-max-width " / 2 + 17rem");
						}
				}
				&-content {
						width: 100%;
						padding: 30px 20px;
						text-align: center;
						background-color: rgba($main-color, 0.9);
						@include media(lg) {
								max-width: $container-max-width/2;
								padding: 9rem 8rem;
								text-align: left;
						}
						h2 {
								margin-bottom: 2.5rem;
						}
						.perex {
							 color: $base-white;
						}
					 	.btn {
								margin-top: 3.5rem;
						}
				}
				&.card-second {
						@include justify-content(center);
						@include media(lg) {
								@include justify-content(flex-start);
						}
						.content-card-item-content-inner {
								@include media(lg) {
										@include flex-block;
										@include justify-content(flex-start);
								}
						}
				}
				&.bg-black {
						.content-card-item-content {
								background-color: rgba($second-color, 0.8);
						}
				}
				&.card-full {
						@include media(lg) {
								@include flex(0 1 100%);
								max-width: 100%;
								@include justify-content(flex-start);
						}
						.content-card-item-inner {
								@include media(lg) {
										@include calc("max-width",  "100% / 2");
								}
						}
				}
		}
}

.top-image {
		background-size: cover;
		background-position: center;
		padding-top: $header-h-small;
		color: $base-white;
		position: relative;
		overflow: hidden;
		@include media(md) {
				padding-top: $header-h-medium;
		}
		@include media(lg) {
				@include justify-content(flex-start);
				padding-top: $header-h-large;
		}
		.pux-container {
				@include flex-block;
		}
		&:not(.gallery-fix):after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				//background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAE0lEQVQYV2NkYGBoAGFGBghoAAAMrQGDXtzIVgAAAABJRU5ErkJggg==");
				background-image: url('/Dare/media/system/img/pattern.png');
				background-repeat: repeat;
		}
		& > div:first-of-type {
				min-height: 100%;
		}
		&-breadcrumbs {
				position: absolute;
				top: 15px;
				left: 0;
				@include media(lg) {
						top: 1.5rem;
				}
		}
		&-inner {
				position: relative;
				@include flex-block;
				@include justify-content(center);
				@include align-items(center);
				min-height: 300px;
				z-index: 99;
				width: 100%;
				@include media(md) {
						min-height: 350px;
				}
				@include media(lg) {
						@include justify-content(flex-start);
							min-height: 39rem;
				}
		}
		&-content {
				max-width: 100%;
				text-align: center;
				z-index: 99;
				@include media(lg) {
						max-width: 60%;
						text-align: left;
				}
				h1 {
						margin-bottom: 10px;
						@include media(lg) {
								margin-bottom: 1.75rem;
						}
						@include media(xxl) {
								margin-bottom: 1.5rem;
						}
				}
				.perex {
						color: inherit;
				}
		}
		&.size-big {
				.top-image-inner {
						min-height: 450px;
						z-index: 99;
						@include media(md) {
								min-height: 550px;
						}
						@include media(lg) {
								min-height: 78rem;
						}
				}
				h1 {
						margin-bottom: 10px;
						@include media(lg) {
								margin-bottom: 4.5rem;
						}
						@include media(xxl) {
								margin-bottom: 3.5rem;
						}
				}
		}
}


.gallery-image {
		background-size: cover;
		background-position: center;
		padding-top: $header-h-small;
		color: $base-white;
		position: relative;
		overflow: hidden;
		@include media(md) {
				padding-top: $header-h-medium;
		}
		@include media(lg) {
				@include justify-content(flex-start);
				padding-top: $header-h-large;
		}
		&-text {
				position: absolute;
				width: 100%;
				min-height: 100%;
				@include flex-block;
				@include calc("min-height", "100% - " $header-h-small);
				@include media(md) {
						@include calc("min-height", "100% - " $header-h-medium);
				}
				@include media(lg) {
						@include calc("min-height", "100% - " $header-h-large);
				}
				&:not(.gallery-fix):after {
						content: "";
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						//background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAE0lEQVQYV2NkYGBoAGFGBghoAAAMrQGDXtzIVgAAAABJRU5ErkJggg==");
						background-image: url('/Dare/media/system/img/pattern.png');
						background-repeat: repeat;
						z-index: 1;
				}
		}
		.pux-container {
				@include flex-block;
				min-height: 100%;
		}
		&-breadcrumbs {
				position: absolute;
				top: 15px;
				left: 0;
				@include media(lg) {
						top: 1.5rem;
				}
		}
		&-inner {
				position: relative;
				@include flex-block;
				@include justify-content(center);
				@include align-items(center);
				min-height: 300px;
				z-index: 99;
				width: 100%;
				@include media(md) {
						min-height: 350px;
				}
				@include media(lg) {
						@include justify-content(flex-start);
							min-height: 39rem;
				}
		}
		&-content {
				max-width: 100%;
				text-align: center;
				z-index: 99;
				@include media(lg) {
						max-width: 60%;
						text-align: left;
				}
				h1 {
						margin-bottom: 10px;
						@include media(lg) {
								margin-bottom: 1.75rem;
						}
						@include media(xxl) {
								margin-bottom: 1.5rem;
						}
				}
				.perex {
						color: inherit;
				}
		}
		&.size-big {
				.top-image-inner {
						min-height: 450px;
						z-index: 99;
						@include media(md) {
								min-height: 550px;
						}
						@include media(lg) {
								min-height: 78rem;
						}
				}
				h1 {
						margin-bottom: 10px;
						@include media(lg) {
								margin-bottom: 4.5rem;
						}
						@include media(xxl) {
								margin-bottom: 3.5rem;
						}
				}
		}
}

.first-slider {
		position: relative;
		&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				//background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAE0lEQVQYV2NkYGBoAGFGBghoAAAMrQGDXtzIVgAAAABJRU5ErkJggg==");
				background-image: url('/Dare/media/system/img/pattern.png');
				background-repeat: repeat;
		}
}

.breadcrumbs {
		@include flex-block;
		@include flex-flow(row, wrap);
		font-size: 11px;
		color: $color-link;
		font-weight: 400;
		@include media(lg) {
				font-size: 1.3rem;
		}
		@include media(xxl) {
				font-size: 1.1rem;
		}
		&-separator {
				padding: 0px 10px;
				color: $base-white;
				@include media(lg) {
						padding: 0px 1.1rem;
				}
				@include media(xxl) {
						padding: 0px 1rem;
				}
		}
		.CMSBreadCrumbsCurrentItem {
				color: $base-white;
		}
		&-single {
				margin-top: $header-h-small;
				padding-top: 1.6rem;
				@include media(md) {
						margin-top: $header-h-medium;
				}
				@include media(lg) {
						margin-top: $header-h-large;
				}
				.breadcrumbs-separator,
				.CMSBreadCrumbsCurrentItem  {
						color: $second-color;
				}
		}
}

.text-block {
		@include media(lg) {
				@include flex-block;
		}
		&-item {
				width: 100%;
				line-height: 1.8;
				&:not(:first-of-type) {
						margin-top: 6rem;
						@include media(lg) {
								margin-top: 0;
						}
				}
				h1,
				h2,
				h3 {
						margin-bottom: 3.25rem;
				}
		}
		&.columns-2 {
				.text-block-item {
						@include media(lg) {
								@include flex(0 1 50%);
								max-width: 50%;
								padding-right: $main-left-md;
						}
						@include media(xxl) {
								padding-right: 80px;
						}
						&.item-2 {
									@include media(lg) {
											padding-right: 0;
											padding-left: $main-left-md;
									}
									@include media(xxl) {
											padding-right: 0px;
											padding-left: 80px;
									}
							}
				}
		}
		&.columns-3 {
				@include media(lg) {
						margin-left: -2rem;
						margin-right: -2rem;
				}
				.text-block-item {
						@include media(lg) {
								@include flex(0 1 33.33333%);
								max-width: 33.33333%;
								padding: 0 2rem;
						}
				}
		}
}

.block-cta {
		background-size: cover;
		background-position: center;
		color: $base-white;
		&-inner {
				padding: 7rem 0;
				text-align: center;
		}
		&-text {
				margin-top: 3rem;
				color: $base-white;
				@include media(lg) {
						margin: 3.5rem auto;
						margin-top: 2rem;
						max-width: 80%;
						position: relative;
				}
		}
		&-content {
				margin-top: 6rem;
				@include media(lg) {
						margin-top: 3.5rem;
						@include flex-block;
				}
				&-item {
						background-color: rgba($second-color, .3);
						padding: 25px 10px;
						font-size: 14px;
						@include media(lg) {
								@include flex-block;
								@include flex-direction(column);
								@include align-items(center);
								@include justify-content(center);
								padding: 3rem;
								font-size: 1.6rem;
								width: 100%;
						}
						@include media(xxl) {
								padding: 2.5rem 3rem;
								font-size: 1.4rem;
								width: 100%;

						}
						&:not(:last-of-type) {
								@include media(lg) {
										margin-right: 0.3rem;
								}
						}
						&:not(:first-child) {
								margin-top: 4px;
								@include media(lg) {
										margin-top: 0;
								}
						}
						a {
								display: inline-block;
								text-decoration: none !important;
								position: relative;
								color: $color-link !important;
								&:after {
										content: "";
										position: absolute;
										bottom: -1px;
										left: 0;
										display: block;
										width: 100%;
										height: 1px;
										background-color: $color-link;
										@include transition(300ms);
										@include media(lg) {
											 bottom: -2px;
										}
								}
								&:hover,
								&:focus {
										&:after {
												left: 50%;
												width: 60%;
												@include translate(-50%, 0);
												//background-color: $main-color;
										}
								}
						}
						big {
								font-weight: 800;
								display: inline-block;
								color: $base-white;
								& + p {
										margin: 0;
										padding: 0;
										margin-top: 10px;
										@include media(lg) {
												margin-bottom: 1.2rem;
										}
										@include media(xxl) {
												margin-bottom: 1rem;
										}
								}
						}
				}
				&.columns-2 {
						.block-cta-content-item {
								@include media(lg) {
										@include flex(0 1 50%);
										max-width: 50%;
								}
						}
				}
				&.columns-3 {
						.block-cta-content-item {
								@include media(lg) {
										@include flex(0 1 33.333333%);
										max-width: 33.333333%;
								}
						}
				}
		}

}

.short-info {
		margin-top: 30px;
		padding-left: 30px;
		position: relative;
		line-height: 1.8;
		font-size: 15px;
		@include media(lg) {
				margin-top: 4.8rem;
				padding-left: 3.5rem;
				font-size: 1.9rem;
		}
		@include media(xxl) {
				margin-top: 4rem;
				padding-left: 3rem;
				font-size: 1.7rem;
		}
		&:before {
				content: "";
				display: block;
				width: 15px;
				height: 15px;
				position: absolute;
				top: 7px;
				left: 0;
				background-image: url("../svg/bullet.svg");
				background-size: cover;
				background-position: center;
				@include media(md) {
						top: 10px;
				}
				@include media(md) {
						top: 3px;
				}
				@include media(lg) {
						top: .6rem;
				}
				@include media(xxl) {
						top: .7rem;
				}
		}
		h4 {
				margin-bottom: 2rem;
		}
}

.gallery-list {
		position: absolute;
		bottom: 0px;
		left: 0;
		width: 100%;
		height: 75px;
		@include flex-block;
		background-color: rgba($base-black, .2);
		z-index: 99;
		@include media(lg) {
				top: $header-h-large;
				min-height: 78rem;
				right: 0;
				left: auto;
				max-width: 12.5rem;
				height: auto;
				display: block;
				margin: 0;
		}
		&-item {
				width: 75px;
				height: 75px !important;
				background-position: center;
				background-size: cover;
				position: relative;
				overflow: hidden;
				cursor: pointer;
				border: 0 !important;
				@include media(lg) {
						width: 12.5rem;
						height: 13rem !important;
				}
				&:after {
						content: "";
						background-color: rgba($color-link, .8);
						display: block;
						position: absolute;
						top: 0px;
						left: 0px;
						right: 0px;
						bottom: 0px;
						opacity: 0;
						margin: 0;
						@include transition(.5s);
				}
				&.slick-current {
						&:after {
								opacity: 1;
						}
				}
		}
		.slick-arrow {
				background-color: rgba($main-color, .9);
				border: 0 !important;
				flex: 0 0 50px;
				width: 50px;
				height: 75px;
				font-size: 0;
				position: relative;
				overflow: hidden;
				@include media(md) {
						flex: 0 0 75px;
						width: 75px;
				}
				@include media(lg) {
						flex: auto;
						width: 12.5rem;
						height: 13rem !important;
				}
				&:after {
						content: "\e900";
						@include flex-block;
						@include align-items(center);
						@include justify-content(center);
						height: 100%;
						width: 100%;
						top: 0;
						left: 0;
						position: absolute;
						font-size: 15px;
						color: $base-white;
						@include transition;
						cursor: pointer;
						@include rotate(-90deg);
						@include media(lg) {
								font-size: 1.7rem;
								@include rotate(0);
						}
						@include media(xxl) {
								font-size: 1.5rem;
						}
				}
				&:hover {
						border: 0 !important;
						outline: 0 !important;
						&:after {
								border: 0 !important;
								outline: 0 !important;
								width: 115%;
								@include media(lg) {
										width: 100%;
										height: 115%;
								}
						}
				}
		}
		.slick-prev {
				&:after {
						content: "\e903";
				}
				&:hover {
						&:after {
								width: 85%;
								@include media(lg) {
										width: 100%;
										height: 85%;
								}
						}
				}
		}
}

.gallery-slider {
		width: 100%;
		min-height: 450px;
		height: 100% !important;
		background-position: center;
		background-size: cover;
		@include media(md) {
				top: $header-h-medium;
				min-height: 550px;
		}
		@include media(lg) {
				top: $header-h-large;
				min-height: 78rem;
		}
		&-inner {
				min-height: inherit;
				height: inherit;
				&-bg {
						background-position: center;
						background-size: cover;
						min-height: inherit;
						height: inherit;
				}
		}
}

.gallery-fix {
		padding-bottom: 75px;
		@include media(lg) {
				padding-bottom: 0px;
		}
}

.accessories-container {
		max-width: 100%;
		width: 100%;
		position: relative;
		padding: 0 15px;
		margin-bottom: 6rem;
		@include media(md) {
				margin: 0 auto;
				max-width: $container-max-width;
				width: 100%;
				padding: 0;
		}
}

.accessories-card,
.contact-card {
		@include flex-block;
		@include flex-direction(column);
		margin: 5rem 0;
		@include media(md) {
				@include flex-direction(row);
				margin: 3rem 0;
				margin-bottom: 5rem;
		}
		&-text {
				width: 100%;
				line-height: 1.8;
				@include media(md) {
						@include calc("padding-left", "2rem +" $main-left-md);
						@include calc("padding-right", "2rem +" $main-right-md);
						@include flex(0 1 50%);
						max-width: 50%;
						@include flex-block;
						@include flex-direction(column);
						@include align-items(flex-start);
						@include justify-content(center);
				}
				& > * {
						max-width: 100%;
				}
				.row {
						width: 100%;
				}
				h1,
				h2,
				h3 {
						margin-top: 20px;
						margin-bottom: 3.25rem;
						@include media(md) {
								margin-top: 0;
						}
						&:not(:first-child) {
								margin-top: 3.25rem;
						}
				}
				h4 {
						margin-bottom: 2.6rem;
						margin-top: 20px;
						@include media(md) {
								margin-top: 0;
						}
						&:not(:first-child) {
								margin-top: 5rem;
						}
				}
				ul {
						width: 100%;
						@include media(lg) {
								columns: 2;
						}
						li {
								@include media(lg) {
										width: 90%;
								}
						}
				}
				table {
						max-width: 400px;
						tr {
								td {
										vertical-align: top;
										padding-bottom: 5px;
										@include media(lg) {
												padding-bottom: 10px;
										}
								}
						}
				}
		}
		&-image {
				order: -1;
				@include media(md)  {
						order: unset;
						order: 0;
						@include flex(0 1 50%);
						max-width: 50%;
				}
				img {
						max-width: 100%;
				}
		}
		&.card-image-small {
				.accessories-card-text {
						@include media(md)  {
								@include flex(0 1 50%);
								max-width: 50%;
						}
						@include media(lg)  {
								@include flex(0 1 60%);
								max-width: 60%;
						}
						@include media(xxl)  {
								@include flex(0 1 70%);
								max-width: 70%;
						}
				}
				.accessories-card-image {
						@include media(md)  {
								@include flex(0 1 50%);
								max-width: 50%;
						}
						@include media(lg)  {
								@include flex(0 1 40%);
								max-width: 40%;
						}
						@include media(xxl)  {
								@include flex(0 1 30%);
								max-width: 30%;
						}
				}
		}
}

.contact-card-wrapper {
		@include media(lg) {
				margin-top: 3rem;
				margin-bottom: 3rem;
		}
}

.card-reference {
		display: block;
		padding-top: 10rem;
		padding-bottom: 0;
		&-holder {
				@include flex-block;
				@include flex-direction(column);
				@include align-items(center);
				@include media(lg) {
						@include flex-direction(row);
				}
				&.card-reverse {
						@include media(lg) {
								@include justify-content(flex-end);
						}
						.content-card-item-inner {
								@include media(lg) {
										@include justify-content(flex-start);
								}
						}
				}
				.btn {
						margin-top: 2rem !important;
				}
		}
		&-testimonial {
				background-color: rgba($base-white, .9);
				padding: 25px 15px;
				margin-top: 10rem;
				color: $second-color;
				text-align: center;
				position: relative;
				@include flex-block;
				@include flex-direction(column);
				@include align-items(center);
				@include media(lg) {
						padding: 5rem 1.7rem;
				}
				@include media(xxl) {
						padding: 4rem 5rem;
				}
				&:before {
						content: "";
						@include triangle-top(20px,20px,20px, rgba($base-white, .9));
						display: block;
						position: absolute;
						top: -20px;
						left: 50%;
						@include translate(-50%, 0);
				}
				&-text {
						max-width: 1200px;
						font-size: 18px;
						font-weight: 300;
						@include media(md) {
								font-size: 22px;
						}
						@include media(lg) {
								font-size: 2.4rem;
						}
						@include media(xxl) {
								font-size: 2.2rem;
						}
				}
				&-name {
						margin-top: 1rem;
						font-size: 14px;
						@include media(md) {
								font-size: 16px;
						}
						@include media(lg) {
								font-size: 1.9rem;
						}
						@include media(xxl) {
								font-size: 1.7rem;
						}
				}
		}
		&.testimonial-none {
				padding: 10rem 0;
		}
}


.reference-small {
		@include media(md) {
				margin-top: 6rem;
				margin-bottom: 6rem;
		}
}

.testimonial {
		background-color: rgba($base-white, .9);
		padding: 25px 15px;
		margin-top: 10rem;
		color: $second-color;
		text-align: center;
		position: relative;
		@include flex-block;
		@include flex-direction(column);
		@include align-items(center);
		@include media(lg) {
				padding: 5rem 1.7rem;
		}
		@include media(xxl) {
				padding: 4rem 5rem;
		}
		&:before {
				content: "";
				@include triangle-top(20px,20px,20px, rgba($base-white, .9));
				display: block;
				position: absolute;
				top: -20px;
				left: 50%;
				@include translate(-50%, 0);
		}
		&-text {
				max-width: 1200px;
				font-size: 18px;
				font-weight: 300;
				@include media(md) {
						font-size: 22px;
				}
				@include media(lg) {
						font-size: 2.4rem;
				}
				@include media(xxl) {
						font-size: 2.2rem;
				}
		}
		&-name {
				margin-top: 1rem;
				font-size: 14px;
				@include media(md) {
						font-size: 16px;
				}
				@include media(lg) {
						font-size: 1.9rem;
				}
				@include media(xxl) {
						font-size: 1.7rem;
				}
		}
		&.theme-dark  {
				background-color: rgba($second-color, .9);
				color: $base-white;
				&:before {
						@include triangle-top(20px,20px,20px, rgba($second-color, .9));
				}
		}
}

.card-job {
		display: block;
		padding: 10rem 0;
		&-holder {
				@include flex-block;
				@include flex-direction(column);
				@include align-items(center);
				@include media(lg) {
						@include flex-direction(row);
				}
				&.card-reverse {
						@include media(lg) {
								@include justify-content(flex-end);
						}
						.content-card-item-inner {
								@include media(lg) {
										@include justify-content(flex-start);
								}
						}
				}
				.card-job-top-title {
						margin-bottom: 2.5rem;
				}
		}
}

.career-text-block {
		overflow: hidden;
		text-align: center;
		@include media(md) {
			text-align: left;
		}
		h1 {
				margin-top: 20px;
				margin-bottom: 4rem;
				@include media(md) {
						margin-top: 2rem;
				}
		}
		.perex {
				margin-bottom: 3rem;
		}
		.text-block-item {
				margin-bottom: 6rem;
		}
}

.argument {
		margin-bottom: 6rem;
		&-item {
				margin-top: 30px;
				text-align: center;
				@include media(md) {
						text-align: left;
				}
				&-image {
						display: block;
						height: 60px;
						@include media(md) {
								height: 80px;
						}
						@include media(xl) {
								height: 100px;
						}
						img {
								width: 60px;
								height: 60px;
								@include scale(0,0);
								@include transition(600ms);
								@include media(md) {
										width: 80px;
										height: 80px;
								}
								@include media(xl) {
										width: 100px;
										height: 100px;
								}
						}

						&.animation {
								img {
										@include scale(1,1);
								}
						}
				}
				&-text {
						margin-top: 2rem;
						line-height: 1.75;
				}
		}
}

.milestones,
.widget-gallery {
		width: 100%;
		margin-top: 30px;
		@include media(lg) {
				margin-top: 5rem;
		}
		position: relative;
		&-item {
				position: relative;
				background-position: center;
				background-size: cover;
				margin-right: 2px;
				display: block;
				&:before {
						content: "";
						display: block;
						padding-top: 100%;
				}
				&-content {
						position: absolute;
						bottom: 4rem;
						left: 4rem;
						@include calc("width", "100% - 4rem");
						height: auto;
						z-index: 9;
						background-color: rgba($main-color, 0.8);
						color: $base-white;
						padding: 3rem 4rem;
						h4 {
								margin-bottom: 1.6rem;
						}
				}
				img {
						display: none;
				}
		}
	  .slick-arrow{
				$arrow-width: 40px;
				$arrow-background: $color-link;

		    position:absolute;
		    top:50%;
		    margin-top: -($arrow-width/2);
		    width: $arrow-width;
		    height: $arrow-width;
		    @include border-radius(50%);
		    font-size:0;
		    background: $arrow-background;
		    outline: 0;
		    z-index: 22;
				color: $base-white;
				@include flex-block;
				@include justify-content(center);
				@include align-items(center);
		    cursor: pointer;
				border: 0;
				box-shadow: 0;
				@include transition;
				&:hover {
						background: $main-color;
				}
		    &:before{
						content: "";
			      font-size: 13px;
		    }
		    &.slick-prev{
						@include calc("left", - $arrow-width / 3);
						@include media(md) {
								@include calc("left", - $arrow-width / 2);
						}
			      &:before{
			        	content: "\e903";
								@include rotate(-90deg);
			      }
		    }
		    &.slick-next{
			      @include calc("right", - $arrow-width / 3);
						@include media(md) {
								@include calc("right", - $arrow-width / 2);
						}
			      &:before{
			        	content: "\e903";
								@include rotate(90deg);
			      }
		    }
				&.slick-disabled {
						opacity: 0;
				}
	  }
}

.widget-gallery {
		.slick-track {
				display: flex;
		}
}

.people {
		&-item {
				text-align: center;
				margin-top: 30px;
				@include media(md) {
						@include flex-block;
						text-align: left;
						margin-top: 6rem;
				}
				&-image {
						border-radius: 50%;
						overflow: hidden;
						width: 100px;
						height: 100px;
						margin-bottom: 10px;
						position: relative;
						display: inline-block;
						@include media(md) {
								display: block;
								margin-right: 30px;
								@include flex(0 0 100px);
						}
						img {
								position: absolute;
								top: -10%;
								left: 0;
								@include scale(1.1, 1.1);
						}
						&.empty-image {
								img {
										position: static;
										transform: none;
								}
						}
				}
				&-content {
						&-position {
								margin-top: 1.6rem;
								color: $color-gray;
						}
						&-text {
								margin-top: 1.8rem;
								line-height: 1.75;
								max-width: 400px;
								display: inline-block;
								@include media(md) {
										display: block;
										max-width:none;
								}
						}
				}
		}
}

.image-wth-text {
		min-height: 400px;
		background-position: center;
		background-size: cover;
		padding: 8rem 0;
		position: relative;
		@include flex-block;
		@include align-items(flex-end);
		@include media(md) {
				min-height: 500px;
		}
		@include media(lg) {
				min-height: 600px;
		}
		&-content {
				background-color: rgba($second-color, 0.9);
				padding: 15px;
				text-align: center;
				color: $base-white;
				@include media(md) {
						padding: 3rem 8rem;
				}
		}
}

#map-canvas {
		width: 100%;
		height: 300px;
		@include media(md) {
				height: 400px;
		}
		@include media(xxl) {
				height: 50rem;
		}
}

.map-points {
	 display: none;
}

.page-404 {
		text-align: center;
		@include flex-block;
		@include justify-content(center);
		@include flex-flow(column);
		@include align-items(center);
		@include calc("min-height", "100vh -" ($header-h-large + 8rem));
		&-image {
				width: 90rem;
				max-width: 90%;
				margin-top: 30px;
				background-image: url('/Dare/media/system/img/404.svg');
				background-size: contain;
				background-position: center;
				position: relative;
				background-repeat: no-repeat;
				&:before {
						content: "";
						display: block;
						width: 100%;
						padding-top: 60%;
				}
				h1 {
						position: absolute;
						top: 50%;
						left: 50%;
						@include translate(-50%, -50%);
				}
		}
		&-content {
				margin-top: 30px;
				@include media(md) {
						margin-top: 50px;
				}
		}
}


.menu-touch {
		display: none;
		&:after {
				content: "\e902";
				@include flex-block;
				@include align-items(center);
				@include pux-static-font(8px);
				position: relative;
				padding: 8px;
				height: 100%;
		}
		&.active {
				@include media(lg) {
					 display: inline-block;
				}
		}
		&.half-item {
				&.active {
						@include media(lg) {
							 display: inline-block;
						}
						@include media(xxl) {
							 display: none !important;
						}
				}
		}
}

.submit-info {
		text-align: center;
		margin-top: 2rem;
}
